export const ROUTE_HOME = '';

export const ROUTE_AUTH = 'auth';
export const ROUTE_REGISTRATION = 'registration';
export const ROUTE_REGISTRATION_CONFIRM = 'registration-confirm';
export const ROUTE_PASSWORD_RECOVERY = 'password-recovery';

export const ROUTE_ANNOUNCEMENT_CREATE = 'announcement-create';
export const ROUTE_ANNOUNCEMENT_EDIT = 'announcement-edit';

export const ROUTE_PROFILE = 'profile';
export const ROUTE_OPEN_CONTACTS = 'open-contacts';
export const ROUTE_SUBSCRIPTION = 'subscription';

export const ROUTE_MY_ANNOUNCEMENTS = 'my-announcements';
export const ROUTE_MY_ANNOUNCEMENTS_DISABLE = 'disable';
export const ROUTE_MY_ANNOUNCEMENTS_DRAFT = 'draft';

export const ROUTE_FAVOURITES = 'favourites';
export const ROUTE_FAVOURITES_USERS = 'users';

export const ROUTE_SETTINGS = 'settings';
export const ROUTE_PROFILE_CONTACTS = 'contacts';
export const ROUTE_PROFILE_SAFETY = 'safety';
export const ROUTE_PROFILE_PRIORITY = 'priority';

export const ROUTE_PRICE_ANALYSIS = 'price-analysis';

export const ROUTE_CONTACTS = 'contacts';

export const ROUTE_ANNOUNCEMENT = 'announcement';
export const ROUTE_CATEGORY = 'category';
export const ROUTE_MAP = 'map';
